<template>
  <v-container class="container__space__footer">
    <v-row class="d-flex justify-center align-center">
      <v-col class="d-flex flex-column justify-center align-center feedback__container">
        <span class="feedback__header">ติชม / ร้องเรียน</span>
        <v-row class="full-width mt-1">
          <v-col cols="12">
            <v-img :src="require('@/assets/images/background/feedback-bg.png')" />
          </v-col>
        </v-row>
        <v-btn-toggle
          v-model="feedbackType"
          borderless
          mandatory
          dense
          class="feedback__button full-width"
        >
          <v-col
            v-for="({ label }, n) in feedbackButtons"
            :key="`feedback-btn-${n}`"
            cols="6" 
            md="3"
            class="px-1 py-1"
          >
            <v-btn
              class="full-width"
              color="secondary"
              outlined
              depressed
            >
              <span>{{ label }}</span>
            </v-btn>
          </v-col>
        </v-btn-toggle>
        <v-row class="full-width">
          <v-col>
            <span class="feedback__textbox__header">แสดงความคิดเห็น</span>
            <v-textarea
              v-model="feedback"
              outlined
              height="80px"
              class="mt-2"
            />
          </v-col>
        </v-row>
        <v-row class="full-width mt-n3">
          <v-col class="d-flex justify-center">
            <v-btn
              color="secondary"
              depressed
              class="full-width"
              :loading="loading"
              :disabled="loading"
              @click="onSubmit"
            >
              <span>ส่งความคิดเห็น</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CHANGE_SNACKBAR_MESSAGE, SHOW_SNACKBAR, CHANGE_SNACKBAR_COLOR } from '@/store/_actionTypes';
import { addComment } from '@/services/feedback';

export default {
  name: 'Feedback',
  data: () => ({
    loading: false,
    feedback: '',
    feedbackType: 0,
    feedbackButtons: [
      {
        label: 'สินค้า',
        value: 'products'
      },
      {
        label: 'การจัดส่ง',
        value: 'delivery'
      },
      {
        label: 'การบริการ',
        value: 'services'
      },
      {
        label: 'อื่นๆ',
        value: 'misc'
      },
    ],
  }),
  computed: {
    ...mapState('user', [ 'accessToken' ]),
  },  
  methods: {
    ...mapActions('app', [ SHOW_SNACKBAR, CHANGE_SNACKBAR_MESSAGE, CHANGE_SNACKBAR_COLOR ]),
    async onSubmit() {
      this.loading = true;
      const comment = this.feedback;
      const commentType = this.feedbackButtons[this.feedbackType].value;
      const result = await addComment(comment, commentType, this.accessToken);

      setTimeout(() => {
        if (result === 'success') {
          this[CHANGE_SNACKBAR_MESSAGE]('ส่งคำร้องสำเร็จ');
          this[CHANGE_SNACKBAR_COLOR]('green');
        } else {
          this[CHANGE_SNACKBAR_MESSAGE]('เกิดข้อผิดพลาด');
          this[CHANGE_SNACKBAR_COLOR]('red');
        }
        this[SHOW_SNACKBAR](true);
        this.loading = false;
        this.feedback = '';
        this.feedbackType = 0;
      }, 1000);
    }
  }
};
</script>

<style lang="scss">
  .feedback__container {
    max-width: 500px;
  }

  .full-width {
    width: 100%;
  }

  .feedback__header  {
    font-family: Kanit;
    font-size: 20px;
    font-style: normal;
    line-height: 1.5;
    color: #242424;
  }

  .feedback__textbox__header  {
    font-family: Kanit;
    font-size: 14px;
    line-height: 1.57;
    color: #242424;
  }

  .feedback__button {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    padding: 12px;
  }

  button  {
    font-family: Kanit;
    font-size: 16px !important;
    line-height: 1.5;
    letter-spacing: unset !important;
  }

  .container__space__footer {
    margin-bottom: 150px;
  }
</style>