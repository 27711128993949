import { post } from '@/helpers/axios';

export const addComment = (comment, commentType, accessToken) => {
  try {
    return post('/users/comments', { comment, commentType }, accessToken);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {};
